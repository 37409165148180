<template>
  <b-container fluid class="p-0 dashboard-page">
    <container-header/>
    <b-row class="d-flex justify-content-center">
      <b-col sm="11" md="7" lg="7" xl="5">
        <select-plan />
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import {PLAN_TEMPLATE_SCREEN} from '@/components/App/User/SelectPlan/planSelectionScreensNames';
import SelectPlan from '@/components/App/User/SelectPlan/SelectPlan.vue';
import ContainerHeader from '@/components/App/UI/ContainerHeader.vue';

export default {
  components: {
    SelectPlan,
    ContainerHeader,
  },
  data: function() {
    const {accountType, planType} = this.$route.params;
    return {
      accountType,
      planType,
    };
  },
  computed: {
    screen: function() {
      return this.accountType && this.planType && PLAN_TEMPLATE_SCREEN;
    },
  },
};
</script>
